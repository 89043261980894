import { createAction, handleActions } from 'redux-actions'
import { guid } from 'utils/nytta'

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

/* ACTIONS
------------------------------------------------- */
export const addNotification = createAction(UPDATE_NOTIFICATIONS, message => ({
  ...message,
  id: guid(),
}))
export const deleteNotification = createAction(REMOVE_NOTIFICATION)

/* Reducer
------------------------------------------------- */
const initialState = {
  messages: [],
}

export default handleActions(
  {
    [UPDATE_NOTIFICATIONS]: (state, { payload }) => {
      const hasMessage = state.messages.find(
        message => message.message === payload.message
      )

      if (hasMessage) {
        return state
      }

      return {
        ...state,
        messages: [...state.messages, payload],
      }
    },
    [REMOVE_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      messages: state.messages.filter(message => message.id !== payload),
    }),
  },
  initialState
)
