declare var AF_CMS_INTEGRATION: 'true' | 'false'
declare var AF_CMS_URL: string

import { createAction, handleActions } from 'redux-actions'
import { get } from 'utils/httpClient'
import { loadState } from './storage'
import {
  localStorageHelperGet,
  localStorageHelperRemove,
  updateReadingDirection,
} from 'utils/nytta'
import gql from 'graphql-tag'
import graphQLClient from './graphQL'
import queryString from 'query-string'
import type { Dispatch } from 'redux'

import { UserQuery } from 'routes/Profile/containers/UserQuery'

export const addLanguageToUser = async (locale: string) => {
  if (!localStorageHelperGet('profile-token')) {
    return
  }

  try {
    await graphQLClient.mutate({
      mutation: UPDATE_USER,
      variables: {
        input: { language: locale },
      },
      refetchQueries: [
        {
          query: UserQuery,
          variables: {
            language: locale,
          },
        },
      ],
    })
  } catch (e) {
    // Old token
    if (e.message === 'GraphQL error: missing permission') {
      localStorageHelperRemove('profile-token')
    }

    throw new Error(e)
  }
}

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`

export const isValidLocale = async (locale: string) => {
  try {
    const { data } = await graphQLClient.query({
      query: GET_USER_LANGUAGES,
    })

    return !!data.userlanguages.find((lang) => lang.id === locale)
  } catch (error) {
    throw new Error(error)
  }
}

/* CONSTANTS
------------------------------------------------- */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const CMS_LINKS = 'translations/CMS_LINKS'

/* ACTIONS
------------------------------------------------- */
export const changeLocale = createAction(CHANGE_LOCALE)

const GET_USER_LANGUAGES = gql`
  query getUserLanguages {
    userlanguages {
      id
    }
  }
`

export const updateLocale = (
  locale: string = 'en',
  forceUpdate: boolean = false
): Function => {
  return async (dispatch: Dispatch<*>) => {
    if (forceUpdate) {
      window.history.pushState({}, '', urlWithoutQueryParams())
    } else {
      const searchLocale = queryString.parse(location.search)
      locale = searchLocale.locale || locale
    }
    if (await isValidLocale(locale)) {
      console.log('Language: ', locale)
      await addLanguageToUser(locale)
      return setLocale(dispatch, locale)
    }
  }
}

function getInitialLocale() {
  try {
    const {
      translations: { locale },
    } = loadState()
    return locale || 'en'
  } catch (ex) {
    return 'en'
  }
}

export const setInitialLocale = (forceUpdate: boolean = false) => {
  return async (dispatch: Dispatch<*>) => {
    const initialLocale = getInitialLocale()
    let localeFromApi
    if (localStorageHelperGet('profile-token')) {
      localeFromApi = await getLanguage()
    }
    let searchLocale
    if (forceUpdate) {
      window.history.pushState({}, '', urlWithoutQueryParams())
    } else {
      searchLocale = queryString.parse(location.search).locale
    }
    const locale = searchLocale || localeFromApi || initialLocale
    if (await isValidLocale(locale)) {
      return setLocale(dispatch, locale)
    }
  }
}

const urlWithoutQueryParams = () => {
  return window.location.href.replace(window.location.search, '')
}

const setLocale = async (dispatch: Dispatch<*>, locale: string = 'en') => {
  try {
    const messages = await get(`/language/${locale}.json`)

    updateReadingDirection(locale)
    dispatch(
      changeLocale({
        locale,
        messages,
      })
    )
  } catch (e) {
    throw new Error(e)
  }
}

export const getCMSLinks = () => {
  return async (dispatch: Dispatch<*>) => {
    if (AF_CMS_INTEGRATION === 'true') {
      try {
        const response = await fetch(`${AF_CMS_URL}/links`)
        const data = await response.json()

        dispatch({
          payload: data,
          type: CMS_LINKS,
        })
      } catch (error) {
        throw new Error(error)
      }
    }
  }
}

const GET_USER_LANGUAGE = gql`
  query getSelectedLanguage {
    user {
      id
      language
    }
  }
`

const getLanguage = async (): Promise<string> => {
  try {
    const { data } = await graphQLClient.query({
      query: GET_USER_LANGUAGE,
    })
    return data.user.language
  } catch (error) {
    console.log('error', error)
    return ''
  }
}

/* Reducer
------------------------------------------------- */
const initialState = {
  links: {},
  locale: 'en',
  messages: null,
}

export default handleActions(
  {
    [CHANGE_LOCALE]: (state, { payload }) => ({
      ...state,
      locale: payload.locale,
      messages: payload.messages,
    }),

    [CMS_LINKS]: (state, { payload }) => ({
      ...state,
      links: payload,
    }),
  },
  initialState
)
