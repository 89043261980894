// @flow

import * as React from 'react'
import { Link } from 'react-router-dom'
import afLogo from 'images/af_logo_blue_2018.svg'
import jobskillsLogo from './img/jobskills_logo.png'
import styled, { injectGlobal } from 'styled-components'

const Wrap = styled.header`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.sizes.medium};
`

const JobskillsLogo = styled.img`
  border-right: 1px solid ${({ theme }) => theme.colors.iron};
  margin-right: ${({ theme }) => theme.sizes.medium};
  max-height: 40px;
  padding-right: ${({ theme }) => theme.sizes.medium};
  vertical-align: middle;

  @media (min-width: 1025px) {
    margin-right: ${({ theme }) => theme.sizes.medium};
    padding-right: ${({ theme }) => theme.sizes.medium};
  }
`

const AfLogo = styled.img`
  max-width: 312px;
  vertical-align: middle;
  width: 70%;

  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (max-width: 767px) {
    max-width: 212px;
    width: auto;
  }
`

injectGlobal`
  html.rtl ${JobskillsLogo} {
    border-left: 1px solid #d6d7d8;
    border-right: 0;
    margin-left: 20px;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 0;
  }
`

const NisHeaderLite = () => {
  return (
    <Wrap data-test="nis-header-lite">
      <Link to="/">
        <JobskillsLogo alt="Jobskills logo" src={jobskillsLogo} />
      </Link>
      <AfLogo alt="Arbetsförmedlingen logo" src={afLogo} />
    </Wrap>
  )
}

export default NisHeaderLite
