// @flow

import { theme } from 'variables/theme'
import Icon from 'components/Icon'
import React from 'react'
import styled from 'styled-components'

const ChevronContainer = styled(Icon)`
  transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  transition: transform 150ms ease-in-out;
`

type ChevronProps = {
  isOpen: boolean,
  size?: number,
}

const Chevron = ({ isOpen, size = 20 }: ChevronProps) => (
  <ChevronContainer
    color={theme.nisColors.links}
    isOpen={isOpen}
    size={size}
    type="chevron-up"
  />
)

export default Chevron
