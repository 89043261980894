// @flow

import * as React from 'react'
import CMSMessage from 'components/CMS/CMSMessage'
import Icon from 'components/Icon/Icon'
import styled, { injectGlobal } from 'styled-components'

type MenuBlockProps = {
  description: string,
  href: string,
  title: string,
}

const MainMenuLink = styled.a`
  align-items: center;
  color: #fff !important;
  display: flex;

  &:hover {
    text-decoration: none;
  }
`

const Content = styled.div`
  flex: 1;
  font-size: 16px;
  margin-right: ${({ theme }) => theme.sizes.third};
`

const CircleIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.nisColors.secondary};
  border-radius: 100%;
  color: #000;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`

injectGlobal`
  html.rtl ${CircleIcon} {
    transform: scaleX(-1);
  }
`

const MenuBlock = ({ description, href, title }: MenuBlockProps) => {
  return (
    <MainMenuLink href={href}>
      <Content>
        <CMSMessage id={title} tagName="h3" />
        <CMSMessage id={description} />
      </Content>
      <CircleIcon>
        <Icon type="angle-right" />
      </CircleIcon>
    </MainMenuLink>
  )
}

export default MenuBlock
