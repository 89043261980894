// @flow

import * as React from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { isLit, lightUpItem } from 'selectors/navigation'
import { withRouter } from 'react-router-dom'
import NavigationItem from './NavigationItem'
import gql from 'graphql-tag'
import styled from 'styled-components'
import type { RootState } from '../../types/af.flow'

type NavigationProps = {
  isLit: boolean,
  lightUp: number,
}

export type NavigationItemType = {
  icon: string,
  text: string,
  badge?: string,
  to: string,
}

const NavContainer = styled.div`
  background-color: ${({ theme }) => theme.nisColors.primary};
`

const NavStyle = styled.nav`
  background-color: ${({ theme }) => theme.nisColors.primary};
  bottom: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 80px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: ${({ isLit }) => (isLit ? 3 : 2)};

  @media (min-width: 801px) {
    background-color: transparent;
    flex-basis: 40%;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    position: relative;
    z-index: ${({ isLit }) => (isLit ? 3 : 1)};
  }
`

export const navigationItems: NavigationItemType[] = [
  {
    icon: 'briefcase',
    text: 'open-positions.title',
    to: '/jobs',
  },
  {
    icon: 'book',
    text: 'globals.career-guide',
    to: '/career',
  },
  {
    icon: 'user',
    text: 'globals.profile',
    to: '/profile',
  },
  {
    icon: 'cog',
    text: 'settings.settings',
    to: '/settings',
  },
]

// No display name
// eslint-disable-next-line
const renderNavigation = (isLit, lightUp) => (item, i) =>
  (
    <NavigationItem
      isLit={isLit}
      item={item}
      key={item.to}
      lightUp={lightUp - 1 === i}
    />
  )
const navigationWithBadge = (hits) => (item) => {
  const displayBadge = item.icon === 'briefcase' && hits > 0
  const badge = hits > 20 ? '20+' : `${hits}`

  return {
    ...item,
    badge: displayBadge ? badge : undefined,
  }
}

export const USER_QUERY = gql`
  query userCityAndExperience {
    user {
      id
      city {
        id
      }
      experience {
        id
        workgroup_id
      }
    }
  }
`
export const RECOMMENDED_OPEN_POS_COUNT_QUERY = gql`
  query openPositionsCount($municipalityIds: [ID], $jobGroupIds: [ID]) {
    openPositions(
      municipalityIds: $municipalityIds
      jobGroupIds: $jobGroupIds
    ) {
      numberOfHits
    }
  }
`

export const Navigation = ({ isLit, lightUp }: NavigationProps) => {
  return (
    <NavContainer>
      <NavStyle isLit={isLit}>
        {navigationItems.map(renderNavigation(isLit, lightUp))}
      </NavStyle>
    </NavContainer>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLit: isLit(state),
  lightUp: lightUpItem(state),
})

export default withRouter(connect(mapStateToProps)(Navigation))
