// @flow

import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SecondaryButtonLink: any = styled(Link).attrs({
  className: 'btn btn-default btn-secondary btn-full',
})``

export default SecondaryButtonLink
