const color = require('./colors')

module.exports = {
  // ---------------------------------------
  // NIS Colors
  // Naming convention inspired by Bootstrap
  // ---------------------------------------

  'color-primary': color['color-stratos'], // #00005A
  'color-links': color['color-deep-koamaru'], // #1616b2

  'color-secondary': color['color-sushi'],

  // Black and White
  'color-black': color['color-black'], // #000000
  'color-white': color['color-white'], // #ffffff

  // Notification color
  'color-info': color['color-deep-koamaru'], // not set
  'color-info-light': color['color-black'], // not set
  'color-success': color['color-black'], // net set
  'color-success-light': color['color-black'], // net set
  'color-warning': color['color-black'], // not set
  'color-warning-light': color['color-black'], // not set
  'color-danger': color['color-thunderbird'], // #BD2321
  'color-danger-light': color['color-we-peep'], // #FAE7EA

  // Grey scale
  'color-gray-dark': color['mine-shaft'], // #333333
  'color-gray': color['color-silver'], // #cccccc
  'color-gray-light': color['color-black'], // not set
  'color-gray-lighter': color['color-concrete'], // #f2f2f2
  'color-gray-lightest': color['color-alabaster'], // #f9f9f9
}
