/* eslint-disable */
// Fixes #619 - MouseEvent constructor does not exists in IE11
// Polyfill from MDN
// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent
;(function(window) {
  try {
    new MouseEvent('test')
    return false // No need to polyfill
  } catch (e) {
    // Need to polyfill - fall through
  }

  // Polyfills DOM4 MouseEvent

  var MouseEvent = function(eventType, params) {
    params = params || { bubbles: false, cancelable: false }
    const mouseEvent = document.createEvent('MouseEvent')
    mouseEvent.initMouseEvent(
      eventType,
      params.bubbles,
      params.cancelable,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )

    return mouseEvent
  }

  MouseEvent.prototype = Event.prototype

  window.MouseEvent = MouseEvent
})(window)
