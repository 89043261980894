import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import {
  localStorageHelperGet,
  localStorageHelperRemove,
  redirect,
} from '../utils/nytta'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import ApolloClient from 'apollo-client'

export const handleErrors = ({ graphQLErrors, networkError }) => {
  const hasGraphQLError = graphQLErrors && graphQLErrors.length > 0

  if (
    hasGraphQLError &&
    graphQLErrors[0].message ===
      "Cannot read property 'permissions' of undefined" &&
    !localStorageHelperGet('profile-token')
  ) {
    redirect('/')
    return
  }

  if (networkError && networkError.statusCode === 503) {
    redirect('/unavailable')
  }
}

const httpLink = createHttpLink({ uri: `${AF_API_URL}/graphql` })

export function deconstructJWT(token) {
  const segments = token.split('.')

  if (!segments instanceof Array || segments.length !== 3) {
    throw new Error('Invalid JWT')
  }

  return JSON.parse(decodeURIComponent(escape(window.atob(segments[1]))))
}

export const authLink = setContext((root, { headers }) => {
  const token = localStorageHelperGet('profile-token')

  // Redirect to login if no token
  if (!token) {
    return { headers }
  }

  // Check if token has expired
  const tokenExpires = deconstructJWT(token).exp

  if (Date.now() - tokenExpires * 1000 > 0) {
    localStorageHelperRemove('profile-token')
    redirect('/')

    return { headers }
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const dataIdFromObject = r => {
  if (r.path && r.__typename) {
    return `${r.__typename}:${r.path}`
  }

  if (r.id && r.__typename) {
    return `${r.__typename}:${r.id}`
  }

  return null
}

const link = ApolloLink.from([onError(handleErrors), authLink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject,
  }),
})

export default client
