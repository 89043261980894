// @flow

import * as React from 'react'
import logoImg from './img/af_logo_vit_2018.svg'
import styled from 'styled-components'

const LogoImage = styled.img`
  display: block;
  max-width: 100%;
`

const Logo = () => (
  <a
    aria-label="Go to Arbetsförmedlingen"
    href="https://www.arbetsformedlingen.se/"
    rel="noopener noreferrer"
    target="_blank"
  >
    <LogoImage alt="Arbetsförmedlingen logo" src={logoImg} />
  </a>
)

export default Logo
