// @flow

import * as React from 'react'
import { Link, type RouterHistory, withRouter } from 'react-router-dom'
import CMSMessage from 'components/CMS/CMSMessage'
import MenuBlock from './MenuBlock'
import Share from 'components/Share/Share'
import closeIcon from 'images/cross_dark.svg'
import menuIcon from 'images/menu.svg'
import styled, { injectGlobal, keyframes } from 'styled-components'
// declare var JOBSKILLS_SEARCH_URL: string

const MenuIcon = styled.img`
  cursor: pointer;
  width: 35px;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const slideIn = (rtl: boolean) => keyframes`
  0% {
    transform: translateX(${rtl ? '-500px' : '500px'});
  }

  100% {
    transform: translateX(0);
  }
`

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-in-out 1;
  background-color: rgba(0, 0, 0, 0.384);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

const Content = styled.div`
  animation: ${slideIn(false)} 200ms ease-in-out 1;
  background-color: rgba(255, 255, 255, 0.952);
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 801px) {
    max-width: 460px;
  }
`

const MenuLink = styled(Link)`
  color: #000 !important;
  display: block;
  font-size: 18px;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.medium};
  }

  @media (min-width: 801px) {
    font-size: 22px;
  }
`

const Links = styled.div`
  padding: 80px ${({ theme }) => theme.sizes.spacing}
    ${({ theme }) => theme.sizes.spacing};
  position: relative;

  @media (min-width: 320px) and (orientation: landscape) {
    padding: 110px ${({ theme }) => theme.sizes.spacing} 80px;
  }

  @media (min-width: 801px) {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    flex: 0.4;
    padding: ${({ theme }) => theme.sizes.spacing};
  }
`

const MenuFooter = styled.div`
  background-color: ${({ theme }) => theme.nisColors.primary};
  color: #fff;
  @media (min-width: 801px) {
    flex: 1;
  }
`

const MenuFooterSection = styled.div`
  padding: ${({ theme }) => theme.sizes.spacing};

  &:first-child {
    border-bottom: 1px solid #333878;
  }
`

const FooterLinks = styled.ul`
  font-size: 16px;
  margin-top: 20px;

  @media (min-width: 801px) {
    font-size: 18px;
    margin-top: 40px;
  }

  li {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.sizes.third};
    }
  }
`

const FooterLink = styled.a`
  color: #fff !important;
  display: block;
  font-size: 16px;

  @media (min-width: 801px) {
    font-size: 18px;
  }
`

const MenuClose = styled.img`
  cursor: pointer;
  position: absolute;
  right: ${({ theme }) => theme.sizes.spacing};
  top: ${({ theme }) => theme.sizes.spacing};
`

const Navigation = styled.div`
  font-size: 1rem;
  left: ${({ theme }) => theme.sizes.spacing};
  position: absolute;
  top: ${({ theme }) => theme.sizes.spacing};

  @media (min-width: 801px) {
    display: none;
  }
`

export const NavigationSeparator = styled.span`
  margin-left: ${({ theme }) => theme.sizes.third};
  margin-right: ${({ theme }) => theme.sizes.third};
`

injectGlobal`
  html.rtl ${Content} {
    animation: ${slideIn(true)} 200ms ease-in-out 1;
    left: 0;
    right: auto;
  }
`

class Menu extends React.Component<
  { history: RouterHistory },
  { open: boolean }
> {
  state = { open: false }
  unlisten = null

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.state.open === true && this.toggleOpen()
    })
  }

  componentWillUnmount() {
    this.unlisten != null && this.unlisten()
  }

  toggleOpen = () => {
    this.setState(state => ({
      open: !state.open,
    }))
  }

  handleKeyToggle = (e: SyntheticKeyboardEvent<HTMLImageElement>) => {
    if (e.keyCode === 13) {
      this.toggleOpen()
    }
  }

  render() {
    return (
      <>
        <MenuIcon
          alt="Toggle menu"
          onClick={this.toggleOpen}
          onKeyUp={this.handleKeyToggle}
          src={menuIcon}
          tabIndex="0"
        />

        {this.state.open && (
          <>
            <Overlay data-testid="menu-overlay" onClick={this.toggleOpen} />
            <Content>
              <Links>
                <Navigation>
                  <CMSMessage id="globals.header.jobseeker" />
                  <NavigationSeparator>/</NavigationSeparator>
                  <a href={JOBSKILLS_SEARCH_URL}>
                    <CMSMessage id="globals.header.employer" />
                  </a>
                </Navigation>
                <MenuClose
                  alt="Close menu"
                  onClick={this.toggleOpen}
                  onKeyUp={this.handleKeyToggle}
                  src={closeIcon}
                  tabIndex="0"
                />
                <MenuLink to="/about">
                  <CMSMessage id="globals.footer.about" />
                </MenuLink>
                <MenuLink to="/terms-and-conditions">
                  <CMSMessage id="globals.footer.terms-link" />
                </MenuLink>
              </Links>
              <MenuFooter>
                <MenuFooterSection>
                  <MenuBlock
                    description="start.employer-body"
                    href={JOBSKILLS_SEARCH_URL}
                    title="start.employer-header"
                  />
                </MenuFooterSection>

                <MenuFooterSection>
                  <FooterLinks>
                    <li>
                      <FooterLink href="https://arbetsformedlingen.se/kontakt/tekniska-problem">
                        <CMSMessage id="globals.footer.support-title" />
                      </FooterLink>
                    </li>
                    <li>
                      <Share />
                    </li>
                    <li>
                      <FooterLink href="https://www.facebook.com/jobskills.se/">
                        <CMSMessage id="globals.follow-on-facebook" />
                      </FooterLink>
                    </li>
                  </FooterLinks>
                </MenuFooterSection>
              </MenuFooter>
            </Content>
          </>
        )}
      </>
    )
  }
}

export default withRouter(Menu)
