// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import CMSMessage from 'components/CMS/CMSMessage'
import CookieWarning from 'components/CookieWarning'
import Margin from 'components/shared/Margin'
import PrimaryButtonLink from 'components/Button/PrimaryButtonLink'
import SecondaryButtonLink from 'components/Button/SecondaryButtonLink'
import cover from 'images/woman.jpg'
import styled from 'styled-components'

const Body = styled.main`
  background-image: url(${cover});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @media (min-width: 801px) {
    justify-content: center;
  }

  @media screen and (min-width: 1024px) and (max-height: 850px) {
    background-position-y: -150px;
  }
`

const Inner = styled.div`
  @media (min-width: 1071px) {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1070px 1fr;
    -ms-grid-columns: 1fr 1070px 1fr;

    > * {
      grid-column: 2;
      -ms-grid-column: 2;
    }
  }
`

const Content = styled.div`
  margin-bottom: 60px;
  padding: 40px;

  @media (max-width: 320px) {
    padding: 20px;
  }

  @media (min-width: 801px) {
    max-width: 50%;
  }

  @media (min-width: 1070px) {
    max-width: 450px;
    padding: 0;
  }
`

const Title = styled.div`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.646);

  @media (min-width: 801px) {
    font-size: 50px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.646);
  }
`

const CreateAccountLink = styled(PrimaryButtonLink)`
  font-size: 19px;
`

const SignInLink = styled(SecondaryButtonLink)`
  border: 0;
  color: #000 !important;
  font-size: 19px;
`

export function Start() {
  return (
    <>
      <FormattedMessage id="globals.footer.about-description">
        {text => (
          <Helmet>
            <meta content={text} name="description" />
            <style type="text/css">
              {`
              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                body { display: flex; }
              }
            `}
            </style>
          </Helmet>
        )}
      </FormattedMessage>

      <Body>
        <Inner>
          <Content>
            <Title>
              <CMSMessage id="start.introduction-title" />
            </Title>
            <CreateAccountLink to="/register">
              <CMSMessage id="register.email-button" />
            </CreateAccountLink>
            <Margin top="8px">
              <SignInLink to="/login">
                <CMSMessage id="globals.sign-in" />
              </SignInLink>
            </Margin>
          </Content>
        </Inner>
        <CookieWarning />
      </Body>
    </>
  )
}

export default Start
