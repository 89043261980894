import Cookies from 'js-cookie'

function curry(fn) {
  const arity = fn.length
  return (function resolver() {
    const memory = Array.prototype.slice.call(arguments)

    return function() {
      const local = memory.slice()

      Array.prototype.push.apply(local, arguments)
      const next = local.length >= arity ? fn : resolver

      return next.apply(null, local)
    }
  })()
}

// General functions
export const mapToId = ({ id, name }: { id: string, name: string }) => ({
  label: name,
  value: id,
})
const mapToName = ({ name }: { name: string }) => ({ label: name, value: name })
export const nameLocaleSort = collator => (
  a: { name: string },
  b: { name: string }
) => collator.compare(a.name, b.name)

// Helper functions
const get = (prop: string) => (obj: Object): any => obj[prop]
const concat = (p: Array<any>, c: Array<any>) => p.concat(c)
const unique = property => (object: Object, index: number, self: Object) =>
  self.findIndex(t => t[property] === object[property]) === index

// Truncate
export const truncate = curry(
  (string: string, length: number): string =>
    string ? `${string.substr(0, length)}...` : undefined
)

/**
 * Add props to object
 * @param {object} item
 * @param {object} newProps
 */
export const addProps = curry(
  (item: Object, newProps: Object): Object => ({
    ...item,
    ...newProps,
  })
)

/**
 * Maps arrays of objects to new values
 */
export const mapOptions = (data: Array<Object>): Array<Object> => {
  if (!data) {
    return []
  }

  return data.map(mapToName)
}

export const mapOptionsToId = (data: Array<Object>): Array<Object> => {
  if (!data) {
    return []
  }

  return data.map(mapToId)
}

/**
 * Alphabetical sorting
 */
export const createCollator = language =>
  new Intl.Collator(language, {
    numeric: true,
    sensitivity: 'base',
  })

export const sortEducationSubjectsAlphabetically = (
  input: Array<{ name: string }>,
  language: string
) => {
  if (!input) {
    return []
  }

  const collator = createCollator(language)
  return input.filter(({ name }) => name).sort(nameLocaleSort(collator))
}

/**
 * Takes a list of counties / muncipalities (query counties)
 * and turns it into a label / value list for select input
 * Cities are always displayed in Swedish, therefore we always
 * sort the list based on Swedish.
 */
export const sortCities = (counties: Array<Object>) => {
  return counties
    .map(get('municipalities'))
    .reduce(concat, [])
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
}

/**
 * Make a pretty string from the area, group and profession
 * @param {Object} work - Object to transform
 * @return {String} - Pretty string
 */
export const professionTitle = (field: {
  name: string,
  group: string,
  area: string,
}) => {
  if (!field) {
    return null
  }

  const { area, group, name } = field

  if (name) {
    return name
  }
  if (group) {
    return group
  }
  if (area) {
    return area
  }

  return null
}

/**
 * Takes a complex object returned by careerGuide query
 * and reconstructs to a basic array with objects
 */
export const prepareCareerGuide = (guide: Object) => {
  if (!guide) {
    return []
  }

  const hasValues = Object.keys(guide)
    .filter(key => guide[key])
    .filter(key => key !== '__typename')

  if (!hasValues.length) {
    return hasValues
  }

  const filterNullInfoAndAddKey = (key: string) => {
    return guide[key]
      .filter(({ info }: { info: string }) => info)
      .map(d => addProps(d, { key }))
  }

  const relevantValues = d =>
    addProps(
      {},
      {
        name: d.name,
        info: d.info,
        taxonomy_id: d.taxonomy_id,
        shortInfo: truncate(d.info, 150),
        key: d.key,
      }
    )

  const filterNullAndNonArrays = key =>
    guide[key] !== null && typeof guide[key] === 'object'

  const createValidHTML = item => ({
    ...item,
    info: item.info
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/\n/gi, '<br />'),
  })

  return Object.keys(guide)
    .filter(filterNullAndNonArrays)
    .map(filterNullInfoAndAddKey)
    .filter(info => info.length > 0)
    .reduce(concat, [])
    .map(createValidHTML)
    .map(relevantValues)
    .filter(unique('taxonomy_id'))
}

/**
 * Redirect to provided URL
 * @param {String} route
 */
export const redirect = (route: string) => (location.href = route)

export const updateReadingDirection = (language: string) => {
  const rtl = ['ar', 'fa']
  const htmlNode: Document = document.documentElement

  if (rtl.includes(language)) {
    htmlNode.classList.remove('ltr')
    htmlNode.classList.add('rtl')
  } else {
    htmlNode.classList.remove('rtl')
    htmlNode.classList.add('ltr')
  }

  htmlNode.setAttribute('lang', language)
}

export const throttle = (fn, threshhold, scope) => {
  threshhold || (threshhold = 250)
  let last
  let deferTimer

  return function() {
    const context = scope || this
    const now = +new Date()
    const args = arguments

    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer)
      deferTimer = setTimeout(function() {
        last = now
        fn.apply(context, args)
      }, threshhold)
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}

/**
 * Guid generator
 * Returns a 36 characters long string
 * @return {string}
 *
 * i.e. guid() -> 8679c194-968b-ee6d-325c-2f6820a252f9
 */

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export const localStorageHelperGet = name => Cookies.get(name)
export const localStorageHelperSet = (name, value, options = {}) => {
  Cookies.set(name, value, options)
}

export const localStorageHelperRemove = name => {
  Cookies.remove(name)
}

export const addGAClickEvent = ({
  event = 'click-tracking-user',
  eventCategory = 'userEngagement',
  eventAction,
  page,
}) => {
  window.dataLayer.push({
    eventCategory,
    eventAction,
    event,
    eventLabel: page,
  })
}

export const addGATrackingToJobsLink = (event, identifier) => {
  const owner = event.currentTarget.ownerDocument
  const url = owner.URL.replace(owner.baseURI, '')

  if (url.includes('jobs')) {
    addGAClickEvent({
      page: `/${url}`,
      eventAction: identifier,
    })
  }
}
