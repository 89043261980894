// @flow

import React from 'react'
import classNames from 'classnames'
import css from './Icon.css'

type IconProps = {
  className?: string,
  color?: string,
  flipped?: boolean,
  size?: number,
  type: string,
  badge?: string,
}

const Icon = ({
  className,
  color,
  flipped,
  size = 20,
  type,
  badge,
}: IconProps) => (
  <i
    className={classNames('fa', `fa-${type}`, className, css.icon, {
      [css.flipped]: flipped,
    })}
    style={{
      color: color,
      fontSize: size,
    }}
    tabIndex={-1}
  >
    {badge && (
      <div
        className={css.badge}
        data-test="job-counter-badge"
        data-testid="job-counter-badge"
      >
        {badge}
      </div>
    )}
  </i>
)

export default Icon
