// @flow

import './App.css'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { hot } from 'react-hot-loader'
import { theme } from './variables/theme'
import App from './App'
import React from 'react'
import ScrollToTop from './routes/ScrollToTop'
import client from './store/graphQL'

const AppContainer = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <Route component={App} path="/" />
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default hot(module)(AppContainer)
