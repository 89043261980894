import { createAction, handleActions } from 'redux-actions'

export const UPDATE_CITY = 'openPositions/UPDATE_CITY'
export const ADD_USER_CITY = 'openPositions/ADD_USER_CITY'
export const UPDATE_TAB = 'openPositions/UPDATE_TAB'
export const UPDATE_WORKGROUP = 'openPositions/UPDATE_WORKGROUP'
export const UPDATE_JOBCATEGORY = 'openPositions/UPDATE_JOBCATEGORY'

/* ACTIONS
------------------------------------------------- */
export const updateCity = createAction(UPDATE_CITY)
export const addUserCity = createAction(ADD_USER_CITY)
export const updateTab = createAction(UPDATE_TAB)
export const updateWorkgroup = createAction(UPDATE_WORKGROUP)
export const updateJobCategory = createAction(UPDATE_JOBCATEGORY)

/* Reducer
------------------------------------------------- */
const initialState = {
  cities: [],
  workgroups: [],
  jobCategories: [],
  tab: 'all',
}

export default handleActions(
  {
    [UPDATE_CITY]: (state, { payload }) => ({
      ...state,
      cities: payload,
    }),

    [ADD_USER_CITY]: (state, { payload }) => {
      return state.cities.length > 0
        ? state
        : {
            ...state,
            cities: [payload],
          }
    },

    [UPDATE_WORKGROUP]: (state, { payload }) => ({
      ...state,
      workgroups: payload,
    }),

    [UPDATE_JOBCATEGORY]: (state, { payload }) => ({
      ...state,
      jobCategories: payload,
    }),

    [UPDATE_TAB]: (state, { payload }) => {
      return {
        ...state,
        tab: payload,
      }
    },
  },
  initialState
)
