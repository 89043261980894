const rgb = (r, g, b) => `rgb(${r}, ${g}, ${b})`
const grayScale = v => rgb(v, v, v)

module.exports = {
  // ------------------------------------
  // Colors
  // ------------------------------------
  'color-bahama-blue': rgb(0, 100, 144), // #006490
  'color-mosque': rgb(4, 85, 115), // #045573
  'color-venice-blue': rgb(6, 90, 121), // #055a79
  'color-allports': rgb(6, 118, 159), // #06769f
  'color-matisse': rgb(19, 118, 157), // #13769d
  'color-eastern-blue': rgb(37, 143, 181), // #258fb5
  'color-pickled-bluewood': rgb(43, 68, 85), // #2b4455
  'color-mariner': rgb(48, 125, 191), // #307dbf
  'color-san-juan': rgb(52, 84, 104), // #345468
  'color-calypso': rgb(54, 116, 153), // #367499
  'color-havelock-blue': rgb(57, 137, 207), // #3989cf
  'color-shakespeare': rgb(62, 138, 204), // #3e8acc
  'color-boston-blue': rgb(66, 139, 202), // #428bca
  'color-limeade': rgb(72, 134, 0), // #488600
  'color-bismark': rgb(78, 114, 136), // #4e7288
  'color-wasabi': rgb(106, 148, 50), // #6a9432
  'color-rolling-stone': rgb(111, 117, 120), // #6F7578
  'color-chartreuse': rgb(123, 213, 0), // #7bd500
  'color-lima': rgb(126, 211, 33), // #7ed321
  'color-oslo-gray': rgb(145, 146, 148), // #919294
  'color-alpine': rgb(186, 139, 51), // #ba8c33
  'color-bombay': rgb(181, 181, 183), // #b5b5b7
  'color-spindle': rgb(181, 216, 233), // #b5d8e9
  'color-silver-sand': rgb(185, 187, 190), // #b9bbbe
  'color-monza': rgb(200, 11, 28), // #c80b1c
  'color-sprout': rgb(201, 217, 182), // #c9d9b6
  'color-tana': rgb(208, 221, 190), // #d0ddbe
  'color-iron': rgb(214, 215, 216), // #d6d7d8
  'color-link-water': rgb(217, 237, 247), // #d9edf7
  'color-kidnapper': rgb(224, 235, 212), // #e0ebd4
  'color-pomegranate': rgb(240, 84, 20), // #f05414
  'color-tango': rgb(242, 123, 36), // #f27b24
  'color-porcelain': rgb(242, 244, 245), // #f2f4f5
  'color-sidecar': rgb(245, 230, 202), // #f5e6ca
  'color-buttercup': rgb(246, 166, 35), // #f6a623
  'color-web-orange': rgb(247, 167, 0), // #f7a700
  'color-athens-gray': rgb(247, 249, 250), // #f7f9fa
  'color-amour': rgb(249, 235, 238), // #f9ebee
  'color-goldenrod': rgb(251, 212, 105), // #fbd469
  'color-champagne': rgb(251, 238, 213), // #fbeed5
  'color-pearl-lusta': rgb(252, 248, 226), // #fcfbe2
  'color-alice-blue': rgb(241, 248, 254), // #f1f8fe

  // ------------------------------------
  // Grayscale
  // ------------------------------------
  'color-dove-gray': grayScale(102), // #666666
  'color-dusty-gray': grayScale(153), // #999999
  'color-alto': grayScale(220), // #dcdcdc
  'color-gallery': grayScale(238), // #eeeeee

  // ------------------------------------
  // NIS Colors
  // ------------------------------------
  'color-stratos': rgb(0, 0, 90), // #00005A
  'color-deep-koamaru': rgb(22, 22, 178), // #1616b2
  'color-thunderbird': rgb(189, 35, 33), // #BD2321
  'color-we-peep': rgb(250, 231, 234), // #FAE7EA,
  'color-pot-pourri': rgb(242, 222, 222), // #F2DEDE
  'color-oyster-pink': rgb(238, 211, 215), // #EED3D7,
  'color-athens-gray': rgb(227, 232, 235), // #E3E8EB,
  'color-sushi': rgb(126, 193, 61), // #7EC13D
  'color-persian-green': rgb(0, 166, 131), // #00A683

  // NIS Grayscale
  'color-black': grayScale(0), // #000000
  'color-mine-shaft': grayScale(51), // #333333
  'color-silver': grayScale(204), // #cccccc
  'color-concrete': grayScale(242), // #f2f2f2
  'color-alabaster': grayScale(249), // #f9f9f9
  'color-white': grayScale(255), // #ffffff
}
