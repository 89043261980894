import { combineReducers } from 'redux'
import modalsReducer from './modals'
import navigationReducer from './navigation'
import notificationsReducer from './notifications'
import openPositionsReducer from './openPositions'
import translationsReducer from './translations'

export const makeRootReducer = asyncReducers => {
  const appReducer = combineReducers({
    modal: modalsReducer,
    navigation: navigationReducer,
    notifications: notificationsReducer,
    openPositions: openPositionsReducer,
    translations: translationsReducer,
    ...asyncReducers,
  })

  return (state, action) => {
    if (action.type === 'LOGOUT_USER') {
      const { translations } = state
      state = { translations }
    }

    return appReducer(state, action)
  }
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
