// @flow

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { currentLocale } from 'selectors/translations'
import CMSMessage from 'components/CMS/CMSMessage'
import React, { Component } from 'react'
import SettingsIcon from './SettingsIcon'
import css from './Header.css'
import styled from 'styled-components'
import type { RootState } from 'types/af.flow'

const HeaderStyle = styled.header`
  background-color: ${({ theme }) => theme.nisColors.primary};
  display: flex;
  padding: ${({ theme }) => theme.sizes.medium};

  @media (screen and (-ms-high-contrast: active), (-ms-high-contrast: none)) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  @media (min-width: 801px) {
    justify-content: center;
  }
`

type HeaderProps = {
  language: string,
  subtitle: string,
  title: string,
}

export class Header extends Component<HeaderProps> {
  shouldComponentUpdate(nextProps: HeaderProps) {
    const { language } = this.props

    return nextProps.language !== language
  }

  render() {
    const { subtitle, title } = this.props

    return (
      <HeaderStyle>
        <div className={css.topwrap}>
          <div className={css.titlewrap}>
            <div className={css.titletextwrap}>
              <Link className={css.title} to="/">
                <CMSMessage id={title} />
              </Link>

              {!!subtitle && (
                <p className={css.subtitle}>
                  <CMSMessage id={subtitle} />
                </p>
              )}
            </div>
            <SettingsIcon />
          </div>
        </div>
      </HeaderStyle>
    )
  }
}

export const mapStateToProps = (state: RootState) => ({
  language: currentLocale(state),
})

export default connect<any, any, any, any, any, any, any>(mapStateToProps)(
  Header
)
