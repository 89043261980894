// @flow

import * as React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { LinkElement } from 'components/Navigation/NavigationItem'
import Icon from 'components/Icon'
import styled from 'styled-components'

// !importants override the styling on "a" from
// AF CSS which has higher specificity
const LinkElem: any = styled(LinkElement)`
  flex: 0.2;
  padding: 12px 0;

  /* Handles iPhone 4/5 and Samsung Galaxy S5 */
  @media (max-width: 360px) {
    flex: 0.5;
  }

  @media (min-width: 801px) {
    display: none;
  }
`

const SettingsIcon = () => {
  return (
    <LinkElem
      activeClassName="active"
      data-test={`header-settings-icon`}
      to="/settings"
    >
      <Icon size={20} type="cog" />
      <FormattedHTMLMessage id="settings.settings" />
    </LinkElem>
  )
}

export default SettingsIcon
