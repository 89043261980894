// @flow

declare var BUILD_INFO: {
  version: string,
  rev: string,
  buildTime: string,
  prefix: string,
}

import * as React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`
type BuildInfoProps = {
  version: string,
  rev?: string,
  buildTime?: string,
  prefix?: string,
}

const BuildInfoContainer = ({
  version,
  rev,
  buildTime,
  prefix,
}: BuildInfoProps) => (
  <>
    <div>
      {prefix}version: {version}
    </div>
    {rev && (
      <div>
        {prefix}rev: {rev}
      </div>
    )}
    {buildTime && (
      <div>
        {prefix}build: {buildTime}
      </div>
    )}
  </>
)

const BuildInfo = () => (
  <Query query={API_BUILD_INFO_QUERY}>
    {({ data }) =>
      data && (
        <Wrapper>
          <div>
            <h4>Build info:</h4>
            <BuildInfoContainer prefix="api-" {...data.buildInfo} />
            <BuildInfoContainer {...BUILD_INFO} />
          </div>
        </Wrapper>
      )
    }
  </Query>
)

export const API_BUILD_INFO_QUERY = gql`
  query apiBuildInfo {
    buildInfo {
      version
      buildTime
      rev
    }
  }
`

export default BuildInfo
