import { createAction, handleActions } from 'redux-actions'

export const setBodyScroll = (scroll, position) => {
  document.body.style.overflow = scroll
  document.body.style.position = position
}

/* CONSTANTS
------------------------------------------------- */
export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'

/* ACTIONS
------------------------------------------------- */
export const closeModal = createAction(MODAL_CLOSE, () => {
  setBodyScroll('visible', 'relative')
  return true
})

export const openModal = createAction(MODAL_OPEN, name => {
  setBodyScroll('hidden', 'fixed')
  return name
})

/* Reducer
------------------------------------------------- */
const initialState = {
  name: '',
  open: false,
}

export default handleActions(
  {
    [MODAL_OPEN]: (state, { payload }) => ({
      ...state,
      name: payload,
      open: true,
    }),
    [MODAL_CLOSE]: () => initialState,
  },
  initialState
)
