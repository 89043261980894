module.exports = {
  'spacing-small': '5px',
  'spacing-third': '10px',
  'spacing-half': '15px',
  'spacing-medium': '20px',
  spacing: '30px',
  'spacing-large': '50px',
  'spacing-extra-large': '100px',
  'spacing-extra-extra-large': '125px',
  'site-max-width': '1140px',
}
