// @flow

import * as React from 'react'
import { Helmet } from 'react-helmet'

const NoIndexHOC = (BaseComponent: any) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class NoIndex extends React.Component<*> {
    render() {
      return (
        <>
          <Helmet>
            <meta content="noindex" name="robots" />
          </Helmet>
          <BaseComponent {...this.props} />
        </>
      )
    }
  }

  return NoIndex
}

export default NoIndexHOC
