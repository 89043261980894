// @flow

import styled from 'styled-components'

const Margin = styled.div`
  margin-bottom: ${({ bottom }) => bottom || 0};
  margin-left: ${({ left }) => left || 0};
  margin-right: ${({ right }) => right || 0};
  margin-top: ${({ top }) => top || 0};
`

export default Margin
