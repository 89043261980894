// @flow

import { FormattedHTMLMessage } from 'react-intl'
import React from 'react'
import alert from './img/icon-alert-red.svg'
import styled, { injectGlobal } from 'styled-components'
import type { NestedError } from './FormSelect'

type FormErrorProps = {
  errorMessage?: string | NestedError,
  'data-test'?: string,
  left?: boolean,
}

export const Wrap = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.sizes.half};
  margin-top: ${({ theme }) => theme.sizes.half};
  position: relative;

  & i {
    margin-right: 5px;
  }
`

export const Icon = styled.img`
  margin-right: ${({ theme }) => theme.sizes.third};
  width: 20px;
`

injectGlobal`
  html.rtl ${Icon} {
    margin-left: 10px;
    margin-right: 0;
  }
`

const FormError = ({
  'data-test': dataTest,
  errorMessage,
  left,
}: FormErrorProps) => {
  if (typeof errorMessage === 'object' && !!errorMessage.name) {
    return (
      <Wrap data-test={dataTest} left={left} role="alert">
        <Icon src={alert} />
        <FormattedHTMLMessage id={errorMessage.name} />
      </Wrap>
    )
  }

  if (typeof errorMessage === 'object' || errorMessage === undefined) {
    return null
  }

  return (
    <Wrap data-test={dataTest} left={left} role="alert">
      <Icon src={alert} />
      <FormattedHTMLMessage id={errorMessage} />
    </Wrap>
  )
}

export default FormError
