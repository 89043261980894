import 'utils/polyfills'
import { Provider } from 'react-redux'
import { loadState, saveState } from './store/storage'
import { throttle } from 'utils/nytta'
import AppContainer from './AppContainer'
import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'

const persistedState = loadState()
const store = createStore(persistedState)

store.subscribe(
  throttle(() => {
    saveState({
      translations: store.getState().translations,
    })
  }, 1000)
)

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer />
    </Provider>,
    document.getElementById('root')
  )
}

// Conditionally polyfill Intl for legacy browsers.
if (!global.Intl) {
  require.ensure(
    [
      'intl',
      'intl/locale-data/jsonp/ar.js',
      'intl/locale-data/jsonp/fa.js',
      'intl/locale-data/jsonp/so.js',
      'intl/locale-data/jsonp/sv.js',
      'intl/locale-data/jsonp/ti.js',
      'intl/locale-data/jsonp/en.js',
      'intl/locale-data/jsonp/uk.js',
      'intl/locale-data/jsonp/ru.js',
    ],
    function(require) {
      require('intl')
      require('intl/locale-data/jsonp/ar.js')
      require('intl/locale-data/jsonp/fa.js')
      require('intl/locale-data/jsonp/so.js')
      require('intl/locale-data/jsonp/sv.js')
      require('intl/locale-data/jsonp/ti.js')
      require('intl/locale-data/jsonp/en.js')
      require('intl/locale-data/jsonp/uk.js')
      require('intl/locale-data/jsonp/ru.js')
      render()
    }
  )
} else {
  render()
}
