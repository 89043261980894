// @flow

import * as React from 'react'
import { Form, Formik } from 'formik'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { currentLocale } from 'selectors/translations'
import { mapToId } from 'utils/nytta'
import { updateLocale } from 'store/translations'
import FormRadio from 'components/Forms/FormElements/FormRadio'
import LanguageSelectionDropdown from './LanguageSelectionDropdown'
import gql from 'graphql-tag'
import type { ValidLocale } from 'types/af.flow'

type LanguageSelectionProps = {
  language: ValidLocale,
  languageSelection?: boolean,
  dropdown?: boolean,
  updateLocale: (locale?: string, force?: boolean) => Function,
}

export const UserLanguagesQuery = gql`
  query getUserLanguages {
    userlanguages {
      id
      name
    }
  }
`

export const LanguageSelection = ({
  language,
  languageSelection,
  dropdown,
  updateLocale,
}: LanguageSelectionProps) => (
  <Query query={UserLanguagesQuery}>
    {({ loading, data, error }) => {
      if (loading || error || !data) {
        return null
      }

      const userLanguages = data.userlanguages || []

      return dropdown ? (
        <LanguageSelectionDropdown
          language={language}
          updateLocale={updateLocale}
          userLanguages={userLanguages}
        />
      ) : (
        <Formik
          initialValues={{ language }}
          onSubmit={() => {
            // Nothing
          }}
          render={() => (
            <Form>
              <FormRadio
                languageSelection={languageSelection}
                name="language"
                onChange={newLocale => {
                  updateLocale(newLocale, true)
                }}
                options={userLanguages.map(mapToId)}
                spaced
              />
            </Form>
          )}
        />
      )
    }}
  </Query>
)

export const mapStateToProps = (state: Object) => ({
  language: currentLocale(state),
})

export const mapDispatchToProps = {
  updateLocale,
}

export default connect<any, any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelection)
