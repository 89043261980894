// @flow

import { FormattedHTMLMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { media } from 'variables/breakpoints'
import CMSMessage from 'components/CMS/CMSMessage'
import FooterLogo from './FooterLogo'
import Margin from 'components/shared/Margin'
import React from 'react'
import Share from 'components/Share/Share'
import styled, { injectGlobal } from 'styled-components'
// declare var JOBSKILLS_SEARCH_URL: string

type FooterProps = {
  noSpacer?: boolean,
}

const FooterWrap = styled.footer`
  background-color: ${({ theme }) => theme.nisColors.primary};
  color: ${({ theme }) => theme.nisColors.white};
  -ms-grid-row: 3;

  ${media.small`
    padding-top: 70px;
    padding-bottom: 60px;
  `};

  ${media.large`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1140px 1fr;
    grid-template-columns: 1fr 1140px 1fr;
  `};
`

const FooterInner = styled.div`
  ${media.large`
    display: -ms-grid;
    display: grid;
    -ms-grid-column: 2;
    -ms-grid-columns: 370px 1fr;
    grid-template-columns: 370px 1fr;
    grid-column-gap: 120px;
    grid-column: 2;
  `};
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  -ms-grid-column: 1;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-right: 120px;
  }
`

const FooterRight = styled.div`
  -ms-grid-column: 2;
`

const FooterSpacer = styled.div`
  height: 63px;

  ${media.small`
    height: 0;
  `};
`

const PaddedSection = styled.div`
  padding: ${({ theme }) => theme.sizes.spacing};

  ${media.large`
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-top: 0;
    }
  `};
`

const LinkSection = styled(PaddedSection)`
  border-bottom: 1px solid #333878;

  ${media.large`
    border-bottom: 0;
  `};
`

const FooterSection = styled(PaddedSection)`
  border-bottom: 1px solid #333878;

  ${media.large`
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  `};
`

const LogoDesktop = styled.div`
  display: none;

  ${media.large`
    display: block;
  `};
`

const LogoMobile = styled.div`
  padding: ${({ theme }) => theme.sizes.spacing};

  ${media.large`
    display: none;
  `};
`

const FooterLink = styled.a`
  color: #fff !important;
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:hover {
    color: var(--color-white);
    text-decoration: underline;
  }
`

const FollowUsDesktop = styled.div`
  display: none;

  ${media.large`
    display: block;
  `};
`

injectGlobal`
  html.rtl ${FooterLeft} {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-left: 120px;
      padding-right: 0;
    }
  }
`

const FooterLinkInternal = FooterLink.withComponent(Link)

const Footer = ({ noSpacer }: FooterProps) => {
  return (
    <FooterWrap>
      <FooterInner>
        <FooterLeft>
          <LinkSection>
            <FooterLink href={JOBSKILLS_SEARCH_URL}>
              <CMSMessage id="globals.footer.for-employers" />
            </FooterLink>

            <FooterLinkInternal to="/terms-and-conditions">
              <CMSMessage id="globals.footer.terms-link" />
            </FooterLinkInternal>
          </LinkSection>

          <LogoDesktop>
            <FooterLogo />
          </LogoDesktop>
        </FooterLeft>

        <FooterRight>
          <FooterSection>
            <FooterLink href="https://arbetsformedlingen.se/kontakt/tekniska-problem">
              <CMSMessage id="globals.footer.support-title" tagName="h3" />
            </FooterLink>
          </FooterSection>

          <FooterSection>
            <CMSMessage id="globals.footer.about" tagName="h3" />
            <FormattedHTMLMessage
              id="globals.footer.about-description-long"
              tagName="p"
            />
            <FollowUsDesktop>
              <FooterLink href="https://www.facebook.com/jobskills.se/">
                <CMSMessage id="globals.follow-on-facebook" />
              </FooterLink>
              <Share />
            </FollowUsDesktop>
          </FooterSection>
        </FooterRight>

        <LogoMobile>
          <FooterLogo />
          <Margin top="20px">
            <FooterLink href="https://www.facebook.com/jobskills.se/">
              <CMSMessage id="globals.follow-on-facebook" />!
            </FooterLink>
          </Margin>
          <Margin top="12px">
            <Share />
          </Margin>
        </LogoMobile>
      </FooterInner>

      {!noSpacer && <FooterSpacer />}
    </FooterWrap>
  )
}

export default Footer
