// @flow

import * as React from 'react'
import CMSMessage from 'components/CMS/CMSMessage'
import Icon from 'components/Icon/Icon'
import styled from 'styled-components'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 801px) {
    align-items: center;
    flex-direction: row;
  }
`

const ShareOptions = styled.div`
  @media (min-width: 801px) {
    margin-left: ${({ theme }) => theme.sizes.third};
  }
`

const ShareOption = styled.a`
  margin-right: ${({ theme }) => theme.sizes.third};
`

type ShareOptionsType = {
  label: string,
  link: string,
  iconType: string,
}

const shareOptions: ShareOptionsType[] = [
  {
    label: 'Share Jobskills via e-mail',
    link: 'mailto:?body=https://jobskills.se',
    iconType: 'envelope',
  },
  {
    label: 'Share Jobskills via Facebook',
    link: 'http://www.facebook.com/sharer.php?u=https://jobskills.se',
    iconType: 'facebook-f',
  },
  {
    label: 'Share Jobskills via Twitter',
    link: 'https://twitter.com/intent/tweet?text=https://jobskills.se',
    iconType: 'twitter',
  },
  {
    label: 'Share Jobskills via LinkedIn',
    link:
      'https://www.linkedin.com/shareArticle?mini=true&url=https://jobskills.se',
    iconType: 'linkedin',
  },
]

const Share = () => {
  return (
    <Wrap>
      <CMSMessage id="share.title" />

      <ShareOptions>
        {shareOptions.map(option => (
          <ShareOption
            aria-label={option.label}
            href={option.link}
            key={option.iconType}
          >
            <Icon color="white" size={20} type={option.iconType} />
          </ShareOption>
        ))}
      </ShareOptions>
    </Wrap>
  )
}

export default Share
