import { createSelector } from 'reselect'

const translationsSelector = state => state.translations

export const currentLocale = createSelector(
  translationsSelector,
  ({ locale }) => locale
)

export const translationStrings = createSelector(
  translationsSelector,
  ({ messages }) => messages
)

export const cmsLinks = createSelector(
  translationsSelector,
  ({ links }) => links
)
