// @flow

import { FormattedMessage } from 'react-intl'
import CMSMessage from 'components/CMS/CMSMessage'
import PrimaryButton from 'components/Button/PrimaryButton'
import React from 'react'
import styled, { injectGlobal } from 'styled-components'

const CookieWrap = styled.div`
  background-color: rgba(217, 237, 247, 0.9);
  bottom: 0;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  min-height: 100px;
  padding: ${({ theme }) => theme.sizes.half};
  position: fixed;
  width: 100%;

  @media (min-width: 801px) {
    padding: ${({ theme }) => theme.sizes.half} 40px;
  }
`

const InnerWrap = styled.div`
  @media (min-width: 801px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

const TextWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.half};
  margin-right: ${({ theme }) => theme.sizes.third};

  span,
  a {
    font-size: 13px;
  }

  a {
    text-decoration: underline;
  }

  > span {
    margin-right: ${({ theme }) => theme.sizes.small};
  }

  @media (min-width: 801px) {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    -ms-grid-column: 1;

    a,
    span {
      font-size: 16px;
    }
  }
`

const ButtonWrap = styled.div`
  align-items: center;
  display: flex;
`

injectGlobal`
  html.rtl ${TextWrap} {
    margin-left: 10px;
    margin-right: 0;
  }
`

type CookieWarningProps = {
  setCookie: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
}

const CookieWarning = ({ setCookie }: CookieWarningProps) => (
  <CookieWrap>
    <InnerWrap>
      <TextWrap>
        <CMSMessage id="start.cookie-warning-text-short" />
        <FormattedMessage id="start.cookie-warning-link">
          {text => (
            <a href={text} title="Cookie link">
              <CMSMessage id="start.cookies-link" />
            </a>
          )}
        </FormattedMessage>
      </TextWrap>
      <ButtonWrap>
        <PrimaryButton onClick={setCookie}>
          <CMSMessage id="start.cookies-accept" />
        </PrimaryButton>
      </ButtonWrap>
    </InnerWrap>
  </CookieWrap>
)

export default CookieWarning
