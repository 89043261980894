import { createSelector } from 'reselect'

const navigationSelector = state => state.navigation

export const isLit = createSelector(
  navigationSelector,
  ({ lightUp }) => lightUp
)

export const lightUpItem = createSelector(
  navigationSelector,
  ({ lightUpItem }) => lightUpItem
)
