// @flow

declare var AF_CMS_INTEGRATION: string
declare var AF_CMS_URL: string

import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { PortalWithState } from 'react-portal'
import { cmsLinks } from 'selectors/translations'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { CMSLinks } from 'types/af.flow'

type CMSMessageProps = {
  className?: string,
  cmsLinks: CMSLinks,
  defaultMessage?: string,
  id: string,
  tagName?: string,
}

type CMSMessageState = {
  link: string,
  x: number,
  y: number,
}

const Message = styled.span`
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 10px rgba(33, 33, 33, 0.3);
  border-top: 3px solid ${({ theme }) => theme.nisColors.primary};
  color: ${({ theme }) => theme.colors.mineShaft};
  display: grid;
  font-size: 14px;
  left: ${props => props.x}px;
  line-height: 1.5;
  position: fixed;
  top: ${props => props.y}px;
  z-index: 1;
  width: 300px;
`

const MessageId = styled.div`
  background-color: rgba(37, 41, 46, 1);
  color: #fff;
  flex: 1;
  padding: 10px;
  text-align: center;
`

const MessageFooter = styled.div`
  display: flex;
`

const MessageLink = styled.a`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.allports};
  color: #fff !important;
  display: flex;
  cursor: pointer;
  justify-content: center;
  padding: 10px 30px;
  text-decoration: none;
  transition: opacity 150ms ease-in-out;

  &:hover {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
  }
`

const cmsIntegration = AF_CMS_INTEGRATION === 'true'

export class CMSMessage extends React.Component<
  CMSMessageProps,
  CMSMessageState
> {
  state = {
    link: '',
    x: 0,
    y: 0,
  }

  messageEl = React.createRef<any>()

  static getDerivedStateFromProps(nextProps: CMSMessageProps) {
    const { cmsLinks = {}, id } = nextProps

    if (cmsLinks[id] == null) {
      return {
        link: 'admin',
      }
    }

    return {
      link: cmsLinks[id],
    }
  }

  handleMouseEnter = (openPortal: () => void) => {
    if (this.messageEl && this.messageEl.current) {
      const { height, x, y } = this.messageEl.current.getBoundingClientRect()

      this.setState(() => ({
        x,
        y: y + height + 20,
      }))

      openPortal()
    }
  }

  render() {
    const { className, defaultMessage, id, tagName } = this.props
    const { link, x, y } = this.state

    if (!cmsIntegration) {
      return <FormattedMessage id={id} tagName={tagName} />
    }

    const CustomTag = tagName ? tagName : 'span'

    return (
      <PortalWithState closeOnOutsideClick>
        {({ openPortal, portal }) => (
          <>
            <CustomTag
              onMouseEnter={() => {
                this.handleMouseEnter(openPortal)
              }}
              ref={this.messageEl}
            >
              <FormattedMessage
                className={className}
                defaultMessage={defaultMessage}
                id={id}
              />
            </CustomTag>
            {portal(
              <Message onClick={e => e.stopPropagation()} x={x} y={y}>
                <MessageFooter>
                  <MessageId>{id}</MessageId>
                  <MessageLink href={`${AF_CMS_URL}/${link}`} target="_blank">
                    CMS
                  </MessageLink>
                </MessageFooter>
              </Message>
            )}
          </>
        )}
      </PortalWithState>
    )
  }
}

const mapStateToProps = state => ({
  cmsLinks: cmsLinks(state),
})

export default connect<any, any, any, any, any, any, any>(mapStateToProps)(
  CMSMessage
)
