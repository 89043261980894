// @flow

import React from 'react'
import classNames from 'classnames'

type SpinnerSize = 'large' | 'small' | 'xsmall'

type SpinnerProps = {
  dataTest?: string,
  size?: SpinnerSize,
}

const Spinner = ({ dataTest, size = 'small' }: SpinnerProps) => (
  <div className="loading-wrapper">
    <div
      aria-label="Wait, information is loading..."
      aria-live="assertive"
      className={classNames('loading-indicator', {
        'li-sm': size === 'small',
        'li-xs': size === 'xsmall',
      })}
      data-test={dataTest ? dataTest : 'spinner'}
      data-testid={dataTest ? dataTest : 'spinner'}
    />
  </div>
)

export default Spinner
