import gql from 'graphql-tag'

export const UserQuery = gql`
  query getProfile($language: LanguageCode) {
    user(language: $language) {
      authentication
      id
      language
      published
      tos_consent_at
      experience {
        id
        info
        name
        taxonomy_id
        taxonomy_path
        taxonomy_type
        years
      }
      skills {
        id
        info
        name
        taxonomy_id
        taxonomy_path
        taxonomy_type
        years
      }
      career {
        id
        info
        name
        taxonomy_id
        taxonomy_path
        taxonomy_type
      }
      languageSkills {
        id
        info
        name
        taxonomy_id
        taxonomy_path
        taxonomy_type
      }
      education {
        hasDegree
        hasDocuments
        hasDocumentsEnglish
        id
        info
        name
        ranking
        taxonomy_id
        taxonomy_path
        taxonomy_type
        years
        educationSubject {
          taxonomy_id
          path
          name
          type
        }
      }
    }
  }
`
