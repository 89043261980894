// @flow

import { FormattedMessage } from 'react-intl'
import { media } from 'variables/breakpoints'
import React from 'react'
import styled from 'styled-components'

type Props = {
  htmlFor: string,
  label: string,
  noMargin?: boolean,
  required?: boolean,
}

const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${({ noMargin, theme }) =>
    noMargin ? 'px' : theme.sizes.third};

  ${media.medium`
    font-size: 18px;
  `};
`

const Required = styled.span`
  color: ${({ theme }) => theme.colors.monza};
  margin-left: 5px;
`

const FormLabel = ({ htmlFor, label, noMargin, required }: Props) => {
  return (
    <FormattedMessage defaultMessage={label} id={label}>
      {text => (
        <Label htmlFor={htmlFor} noMargin={noMargin}>
          {text}
          {required && <Required>*</Required>}
        </Label>
      )}
    </FormattedMessage>
  )
}

export default FormLabel
