// @flow

import { Field, connect } from 'formik'
import CMSMessage from 'components/CMS/CMSMessage'
import FormError from './FormError'
import FormLabel from './FormLabel'
import React, { Component } from 'react'
import classnames from 'classnames'
import css from './FormRadio.css'
import type { FormikContext } from 'types/af.flow'

type FormRadioProps = {
  formik: FormikContext,
  label?: string,
  languageSelection?: boolean,
  name: string,
  onChange?: Function,
  options: { value: string | boolean, label: string }[],
  required?: boolean,
}

export class FormRadio extends Component<FormRadioProps> {
  shouldComponentUpdate(nextProps: FormRadioProps) {
    const { formik, name } = this.props

    return (
      nextProps.formik.values[name] !== formik.values[name] ||
      nextProps.formik.touched[name] !== formik.touched[name] ||
      nextProps.formik.errors[name] !== formik.errors[name]
    )
  }

  handleChange = (value: string | boolean) => {
    this.props.formik.setFieldValue(this.props.name, value)

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  handleKeyUp = (
    e: SyntheticKeyboardEvent<HTMLDivElement>,
    value: string | boolean
  ) => {
    if (e.keyCode === 13) {
      this.handleChange(value)
    }
  }

  render() {
    const {
      formik,
      label,
      languageSelection,
      name,
      options,
      required,
    } = this.props

    const error = formik.errors[name]
    const touched = formik.touched[name]
    const value = formik.values[name]

    return (
      <div
        className={classnames({
          [css.horizontal]: languageSelection,
        })}
      >
        {label && (
          <div>
            <FormLabel htmlFor={name} label={label} required={required} />
          </div>
        )}

        <div
          className={classnames(css.inputs, {
            [css.spaced]: languageSelection,
            [css.horizontal]: languageSelection,
          })}
        >
          {options.map((option, i) => {
            return (
              <div
                className={classnames({
                  [css.wrapper]: !languageSelection,
                })}
                data-test={`formradio-${option.label}`}
                key={`radio-${name}-${i}`}
                onClick={() => this.handleChange(option.value)}
                onKeyUp={e => this.handleKeyUp(e, option.value)}
                tabIndex="0"
              >
                <label className={css.formRadio} htmlFor={`${name}-${i}`}>
                  <Field
                    checked={value === option.value}
                    className={css.radio}
                    id={`${name}-${i}`}
                    name={name}
                    type="radio"
                    value={value}
                  />
                  <label className={css.customRadio} htmlFor={`gender-${i}`} />

                  <label className={css.label} htmlFor={`${name}-${i}`}>
                    {name === 'language' ? (
                      // preventing React-Intl from complaining
                      option.label
                    ) : (
                      <CMSMessage
                        defaultMessage={option.label}
                        id={option.label}
                      />
                    )}
                  </label>
                </label>
              </div>
            )
          })}
        </div>

        {!!error && touched && <FormError errorMessage={error} />}
      </div>
    )
  }
}

export default connect<any, any, any, any, any, any, any>(FormRadio)
