// @flow

import * as React from 'react'
import Icon from 'components/Icon'
import classnames from 'classnames'
import styled, { injectGlobal } from 'styled-components'

type PrimaryButtonProps = {
  children: React.Node,
  'data-test'?: string,
  icon?: string,
  onClick?: Function,
  size?: 'small' | 'medium' | 'large' | 'full',
  type?: 'button' | 'submit',
  full?: boolean,
}

const IconStyle = styled.span`
  margin-right: 10px;
`

injectGlobal`
  html.rtl ${IconStyle} {
    margin-right: 0;
    margin-left: 10px;
  }
`

const PrimaryButton = ({
  icon,
  children,
  'data-test': dataTest,
  onClick,
  size = 'medium',
  type = 'button',
  full = true,
}: PrimaryButtonProps) => {
  const btnClass = classnames('btn btn-primary', {
    'btn-full': full,
    'btn-sm': size === 'small',
    'btn-lg': size === 'large',
  })

  return (
    <button
      className={btnClass}
      data-test={dataTest}
      data-testid={dataTest}
      onClick={onClick}
      type={type}
    >
      {icon && (
        <IconStyle>
          <Icon type={icon} />
        </IconStyle>
      )}
      {children}
    </button>
  )
}

export default PrimaryButton
