// @flow

import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import Footer from 'components/Footer/Footer'
import StrippedFooter from 'components/Footer/StrippedFooter'

export const noop = () => null

const AppFooter = () => {
  return (
    <Switch>
      <Route component={StrippedFooter} exact path="/" />
      <Route component={StrippedFooter} path="/about" />
      <Route component={noop} path="/login" />
      <Route component={noop} path="/register" />
      <Route component={noop} path="/forgot-password" />
      <Route component={noop} path="/reset-password" />
      <Route component={noop} path="/unavailable" />
      <Route component={noop} path="/accept-terms-and-conditions" />
      <Route component={noop} path="/error/generic" />
      <Route component={Footer} path="*" />
    </Switch>
  )
}

export default AppFooter
