/* eslint-disable */

import 'what-input'
import * as React from 'react'
import { FormattedMessage, IntlProvider, addLocaleData } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { currentLocale, translationStrings } from './selectors/translations'
import { getCMSLinks, setInitialLocale } from './store/translations'
import { Helmet } from 'react-helmet'
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import Spinner from './components/Spinner/Spinner'
import Start from './routes/Start/Start'
import arLocaleData from 'react-intl/locale-data/ar'
import faLocaleData from 'react-intl/locale-data/fa'
import noindex from './routes/NoIndex'
import restricted from './routes/restricted'
import soLocaleData from 'react-intl/locale-data/so'
import styled from 'styled-components'
import svLocaleData from 'react-intl/locale-data/sv'
import tiLocaleData from 'react-intl/locale-data/ti'
import ruLocaleData from 'react-intl/locale-data/ru'
import uaLocaleData from 'react-intl/locale-data/uk'
import BuildInfo from './components/System/BuildInfo'

import '@fontsource/open-sans' // Defaults to weight 400.

const Login = React.lazy(() => import('./routes/Login/Login'))
const Settings = React.lazy(() =>
  import('./routes/Settings/containers/SettingsContainer')
)
const Career = React.lazy(() =>
  import('./routes/Career/containers/CareerContainer')
)
const NotFound = React.lazy(() => import('./routes/NotFound/NotFound'))
const Unavailable = React.lazy(() => import('./routes/Unavailable'))
const ResetPassword = React.lazy(() => import('./routes/ResetPassword'))
const GenericError = React.lazy(() => import('./routes/Errors/GenericError'))
const About = React.lazy(() => import('./routes/About/About'))

const EnterDetails = React.lazy(() =>
  import('./routes/Register/EnterDetails/EnterDetails')
)
const VerifyEmail = React.lazy(() =>
  import('./routes/Register/VerifyEmail/VerifyEmail')
)
const Profile = React.lazy(() =>
  import('./routes/Profile/containers/ProfileContainer')
)
const EmailRegister = React.lazy(() =>
  import('./routes/Register/EmailRegister/EmailRegister')
)
const TermsAndConditions = React.lazy(() =>
  import('./routes/TermsAndConditions/TermsAndConditions')
)
const TermsAndConditionsCopy = React.lazy(() =>
  import('./routes/TermsAndConditions/TermsAndConditionsCopy')
)
const OpenPositions = React.lazy(() =>
  import('./routes/OpenPositions/OpenPositions')
)
const OpenPosition = React.lazy(() =>
  import('./routes/OpenPosition/OpenPosition')
)
const Recommendations = React.lazy(() =>
  import('./routes/Register/Recommendations/Recommendations')
)
const RequestPasswordReset = React.lazy(() =>
  import('./routes/ForgotPassword/RequestPasswordReset')
)
const PasswordResetRequested = React.lazy(() =>
  import('./routes/ForgotPassword/PasswordResetRequested')
)
const SocialAccountsClosedInformation = React.lazy(() =>
  import('./routes/ConvertSocial/SocialAccountsClosedInformation')
)

addLocaleData([
  ...arLocaleData,
  ...faLocaleData,
  ...soLocaleData,
  ...svLocaleData,
  ...tiLocaleData,
  ...ruLocaleData,
  ...uaLocaleData
])

const SpinnerWrap = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`

export class App extends React.Component {
  componentDidMount() {
    this.props.setInitialLocale()
    this.props.getCMSLinks()
  }

  render() {
    const { locale, messages } = this.props
    if (!messages) {
      return null
    }

    //uk is ukranian for react-intl package
    const selectedLocale = locale === 'ua' ? 'uk' : locale

    return (
      <IntlProvider key={locale} locale={selectedLocale} messages={messages}>
        <>
          <FormattedMessage id="globals.footer.about-description">
            {(text) => (
              <Helmet>
                <meta content={text} property="og:description" />
              </Helmet>
            )}
          </FormattedMessage>

          <AppHeader />
          <React.Suspense
            fallback={
              <SpinnerWrap>
                <Spinner />
              </SpinnerWrap>
            }
          >
            <Switch>
              <Route component={Start} exact path="/" />
              <Route component={About} path="/about" />

              {/* System build info */}
              <Route component={BuildInfo} exact path="/buildinfo" />

              {/* Login */}
              <Route component={noindex(Login)} exact path="/login" />
              <Route
                component={SocialAccountsClosedInformation}
                exact
                path="/login/social-accounts-closed"
              />

              {/* Register */}
              <Route
                exact
                path="/register"
                render={() => <Redirect to="/register/recommendations" />}
              />
              <Route
                component={noindex(Recommendations)}
                exact
                path="/register/recommendations"
              />
              <Route
                component={noindex(EmailRegister)}
                exact
                path="/register/email-register"
              />
              <Route component={VerifyEmail} path="/register/verify-email" />
              <Route
                component={EnterDetails}
                exact
                path="/register/enter-details"
              />

              {/* Forgot password */}
              <Route
                component={noindex(RequestPasswordReset)}
                exact
                path="/forgot-password"
              />
              <Route
                component={PasswordResetRequested}
                exact
                path="/forgot-password/done"
              />
              <Route
                component={noindex(ResetPassword)}
                path="/reset-password"
              />

              {/* Terms and conditions */}
              <Route
                component={noindex(TermsAndConditionsCopy)}
                exact
                path="/terms-and-conditions"
              />
              <Route
                component={TermsAndConditions}
                exact
                path="/accept-terms-and-conditions"
              />

              {/* Find jobs */}
              <Route
                component={restricted(OpenPosition)}
                exact
                path={`/jobs/:id`}
              />
              <Route component={restricted(OpenPositions)} exact path="/jobs" />

              {/* Profile */}
              <Route component={restricted(Profile)} path="/profile" />
              <Route component={restricted(Career)} path="/career" />
              <Route component={restricted(Settings)} path="/settings" />

              {/* Errors and 404 */}
              <Route component={Unavailable} path="/unavailable" />
              <Route component={GenericError} path="/error/generic" />
              <Route component={NotFound} />
            </Switch>
          </React.Suspense>

          <AppFooter />
        </>
      </IntlProvider>
    )
  }
}

export const mapStateToProps = (state) => ({
  locale: currentLocale(state),
  messages: translationStrings(state),
})

export const mapDispatchToProps = {
  getCMSLinks,
  setInitialLocale,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
