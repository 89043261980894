// @flow

import * as React from 'react'
import FooterLogo from './FooterLogo'
import styled from 'styled-components'

const Footer = styled.footer`
  align-items: center;
  background-color: ${({ theme }) => theme.nisColors.primary};
  display: flex;
  justify-content: center;
  padding: 40px;

  @media (min-width: 801px) {
    padding-bottom: 90px;
    padding-top: 75px;

    img {
      width: 400px;
    }
  }

  @media screen and (min-width: 1024px) and (max-height: 850px) {
    padding: 40px;

    img {
      max-width: 300px;
      width: auto;
    }
  }
`

const StrippedFooter = () => {
  return (
    <Footer>
      <FooterLogo />
    </Footer>
  )
}

export default StrippedFooter
