// @flow

import { Link } from 'react-router-dom'
import styled from 'styled-components'

const PrimaryButtonLink: any = styled(Link).attrs({
  className: 'btn btn-primary btn-full',
})`
  /* This overwrites the global styling of a */
  color: #fff !important;
`

export default PrimaryButtonLink
