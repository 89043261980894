import { createAction, handleActions } from 'redux-actions'

export const LIGHT_ON = 'navigation/LIGHT_ON'
export const LIGHT_OFF = 'navigation/LIGHT_OFF'

/* ACTIONS
------------------------------------------------- */
export const lightOn = createAction(LIGHT_ON)
export const lightOff = createAction(LIGHT_OFF)

/* Reducer
------------------------------------------------- */
const initialState = {
  lightUp: false,
  lightUpItem: 0,
}

export default handleActions(
  {
    [LIGHT_ON]: (state, { payload }) => ({
      ...state,
      lightUp: true,
      lightUpItem: payload,
    }),

    [LIGHT_OFF]: () => initialState,
  },
  initialState
)
