// @flow

import * as React from 'react'
import CookieWarning from './CookieWarning'
import Cookies from 'js-cookie'

const CookieWarningContainer = () => {
  const [hasCookie, setCookie] = React.useState(false)

  React.useEffect(() => {
    if (Cookies.get('cookie-consent')) {
      setCookie(true)
    }
  }, [])

  const updateCookie = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    Cookies.set('cookie-consent', true, { expires: 365 })
    setCookie(true)
  }

  if (hasCookie) {
    return null
  }

  return <CookieWarning setCookie={updateCookie} />
}

export default CookieWarningContainer
