// @flow

import * as React from 'react'
import { localStorageHelperGet } from 'utils/nytta'
import { withRouter } from 'react-router-dom'

type RestrictedProps = {
  history: {
    replace: Function,
  },
  location: {
    pathname: string,
  },
}

type RestrictedState = {
  isAuthenticated: boolean,
}

const RestrictedHOC = (BaseComponent: any) => {
  class Restricted extends React.Component<RestrictedProps, RestrictedState> {
    state = {
      isAuthenticated: false,
    }

    componentDidMount() {
      this.checkAuthentication(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.location.pathname !== this.props.location.pathname) {
        this.checkAuthentication(nextProps)
      }
    }

    checkAuthentication(props) {
      if (!localStorageHelperGet('profile-token')) {
        props.history.replace({ pathname: '/' })
        return
      }

      this.setState({
        isAuthenticated: true,
      })
    }

    render() {
      if (!this.state.isAuthenticated) {
        return null
      }

      return <BaseComponent {...this.props} />
    }
  }

  return withRouter(Restricted)
}

export default RestrictedHOC
