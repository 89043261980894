// @flow

import * as React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import Icon from 'components/Icon'
import classNames from 'classnames'
import styled, { css, injectGlobal, keyframes } from 'styled-components'
import type { NavigationItemType } from './Navigation'

type NavigationItemProps = {
  item: NavigationItemType,
  isLit: boolean,
  lightUp: boolean,
}

const slideDown = keyframes`
0% {
  transform: translateX(-50%) translateY(-10px);

}
100% {
  transform: translateX(-50%) translateY(0);
}
`

const slideUp = keyframes`
0% {
  transform: translateX(-50%) translateY(20px);
}

100% {
  transform: translateX(-50%) translateY(0);
}
`

const borderColor = ({ lightUp, theme }) =>
  lightUp ? theme.nisColors.secondary : 'transparent'

const lightUpCss = (lightUp: boolean) => css`
  border-top: 4px solid ${props => borderColor(props)};
  transition: border 300ms ease-in-out;

  @media (min-width: 801px) {
    border-bottom: 4px solid ${props => borderColor(props)};
  }

  > * {
    opacity: ${lightUp ? 1 : 0.3};
    transition: opacity 200ms ease-in-out;
  }

  ${lightUp &&
    `
    &:before {
      animation: ${slideDown} 1s 1; 
      background-color: transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      content: '';
      left: 50%;
      opacity: 1;
      position: absolute;
      top: -35px;
      transform: translateX(-50%);
    }

    @media (min-width: 801px) {
      &:before {
        animation: ${slideUp} 1s 1;
        border-bottom: 15px solid #fff;
        border-top: 0;
        bottom: -30px;
        top: auto;
      }
    }
    `};

  &:after {
    opacity: 0.3;
  }

  &.active {
    border-top: 4px solid ${props => borderColor(props)};

    @media (min-width: 801px) {
      border-bottom: 4px solid ${props => borderColor(props)};
      border-top: none;
    }
  }
`

// !importants override the styling on "a" from
// AF CSS which has higher specificity
export const LinkElement = styled<any>(NavLink)`
  align-items: center;
  border-top: 4px solid transparent;
  color: #fff !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  padding: 12px;
  position: relative;
  text-align: center;
  text-decoration: none !important;

  /* Handles iPhone 4/5 and Samsung Galaxy S5 */
  @media (max-width: 360px) {
    font-size: 12px;
    hyphens: auto;
    word-break: break-word;
  }

  @media (min-width: 801px) {
    border-bottom: 4px solid transparent;
    border-top: none;
    flex: 0;
    flex-basis: 20%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & span {
      width: 100%;
    }
  }

  &:hover {
    color: #fff;
  }
`

// Handle unknown DOM props
// https://github.com/styled-components/styled-components/issues/135
const LinkElem = styled<any>(
  ({
    activeClassName,
    children,
    className,
    'data-test': dataTest,
    isLit,
    to,
  }) => {
    const Element: any = isLit ? LinkElement.withComponent('div') : LinkElement
    const classes = classNames(className, {
      [activeClassName]: isLit,
    })

    return (
      <Element
        activeClassName={activeClassName}
        className={classes}
        data-test={dataTest}
        to={to}
      >
        {children}
      </Element>
    )
  }
)`
  &:last-of-type {
    display: none;
  }

  @media (min-width: 801px) {
    border-bottom: 4px solid transparent;
    border-top: none;
    flex: 0;
    flex-basis: 20%;

    &:last-of-type {
      display: flex;
    }
  }

  &:first-of-type:after {
    content: none;
  }

  &:after {
    background-color: ${({ theme }) => theme.colors.bismark};
    bottom: 20%;
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    width: 1px;
  }

  &.active {
    border-top: 4px solid ${({ theme }) => theme.nisColors.secondary};

    @media (min-width: 801px) {
      border-bottom: 4px solid ${({ theme }) => theme.nisColors.secondary};
      border-top: none;
    }
  }

  ${({ isLit, lightUp }) => isLit && lightUpCss(lightUp)};
`

injectGlobal`
  html.rtl {
    ${LinkElem}:last-of-type:after,
    ${LinkElem}:last-of-type:before {
      content: none;
    }

    ${LinkElem}:first-of-type:after {
      background-color: ${({ theme }) => theme.colors.bismark};
      bottom: 20%;
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      width: 1px;
    }
  }

  html[lang="so"] {
    ${LinkElem} {
      @media (max-width: 480px) { 
        font-size: 12px;
        padding: 6px;
      }
    }
  }
`

const NavigationItem = ({ isLit, item, lightUp }: NavigationItemProps) => {
  return (
    <LinkElem
      activeClassName="active"
      data-test={`navigation-item-${item.to}`}
      isLit={isLit}
      lightUp={lightUp}
      to={item.to}
    >
      <Icon badge={item.badge} size={20} type={item.icon} />
      <FormattedHTMLMessage id={item.text} />
    </LinkElem>
  )
}

export default NavigationItem
